import { useEffect, useState } from "react";
import "./App.css";
import { useThemeHook } from "./components/ThemeProvider";
import PreLoader from "./components/preloader/PreLoader";
import Routers from "./routers/Routers";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { AddToCart } from "./redux/slices/cartSlice";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const [theme] = useThemeHook();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fetchStatus = async () => {
    axios
      .post("https://api.foodyno.gomaplus.tech/api/order/getOrderForEdit", {
        table_id: localStorage.getItem('table_id'),
        branch_id: 1,
      })
      .then((response) => {
        localStorage.setItem("status", response.data.data.status);
        localStorage.setItem("order_id", response.data.data.id);
        console.log(response.data.data, "edit order data");
        console.log(response.data.data.status, "edit order status");
        if (response.data.data.status === 1) {
          const cartItems = [];
          let i = 0;
          response.data.data.products.forEach((item) => {
            let newPrice = 0;
            for (let index = 0; index < item.extra.length; index++) {
              newPrice += item.extra[index].price_per_piece;
            }

            const cartItem = {
              nourId: i,
              id: item.id,
              name: item.name,
              name_ar: item.name_ar,
              image: item.image,
              price: newPrice + item.price,
              extraIngredients: item.extra,
              note: item.note == null ? "" : item.note,
              quantity: item.qty,
              removedIngredients: item.removeIngredient,
            };

            i++;
            cartItems.push(cartItem);
          });
          cartItems.forEach((cartItem) => {
            dispatch(AddToCart(cartItem));

          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data from the API:", error);
        localStorage.setItem("status", 3);
        dispatch(AddToCart(clearCart));

      });
  };
  useEffect(() => {
    fetchStatus();
    const unlisten = () => {
      fetchStatus(); // Call fetchStatus() every time the URL path changes
    };
    return unlisten;
  }, [location.pathname]);

  return (
    <>
      {/* <PreLoader /> */}
      <div className={`${theme ? "bg-[#171717]" : " bg-white"} App`}>
        <Routers />
      </div>
    </>
  );
}

export default App;










