import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const cartApi = createApi({
  reducerPath: "cartApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://api.foodyno.gomaplus.tech/api" }),
  endpoints: (builder) => ({
    addToCart: builder.mutation({
      query: (cartData) => {
        const data = cartData.products;
        const products = data.map((item) => {
          return {
            product_id: item.id,
            extraIngredients: item.extraIngredients.map((ing) => {
              return { ingredient_id: ing.id };
            }),
            removedIngredients: item.removedIngredients.map((remove) => {
              return { remove_id: remove.id };
            }),
            qty: item.quantity,
            note: item.note,
          };
        });
        
        const datatosend = {
          table_id: localStorage.getItem('table_id'),
          branch_id: 1,
          products,
        };

        console.log(datatosend, "data to send to api");
        const status = localStorage.getItem("status");
        const orderId = localStorage.getItem("order_id");
        if (status == 1) {
          console.log("edit order");
          return {
            url: `order/${orderId}`,
            method: "POST",
            body: datatosend,
          };
        } else {
          console.log("new order");
          return {
            url: "order/add",
            method: "POST",
            body: datatosend,
          };
        }
      },
    }),
  }),
});

export const { useAddToCartMutation } = cartApi;
