import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const offersApi = createApi({
  reducerPath: "offersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.foodyno.gomaplus.tech/api/",
  }),
  endpoints: (builder) => ({
    getAllOffers: builder.query({
      query: () => "offer/branch/1",
    }),
  }),
});

export const { useGetAllOffersQuery } = offersApi;
